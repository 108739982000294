import styled from 'styled-components';
import colors from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const FormContainer = styled.div`
  width: 400px;
  padding: 2em;
  background: rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  ${MEDIA.DESKTOP`
    margin: 0 auto;
    width: 100%;
  `}
`;

export const Section = styled.div``;

export const StyledForm = styled.form`
  display: block;
  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const StyledInput = styled.input`
  margin: 1em 0;
  display: block;
  width: 100%;
  border: none;
  padding: 1em;
  box-sizing: border-box;
`;

export const StyledTextArea = styled.textarea`
  margin: 1em 0 3em;
  padding: 1em;
  display: block;
  width: 100%;
  border: none;
  height: 80px;
  resize: none;
  box-sizing: border-box;
`;

export const CustomInputLabel = styled.label``;

export const StyledButton = styled.button`
  width: 200px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  transition: background ease-in-out 0.1s;
  background: ${colors.color1};
  color: white;
  border-radius: 5px;
  font-size: 1em;
  &:hover {
    cursor: pointer;
    background: ${colors.color2};
    color: ${colors.color1};
    border: 1px solid ${colors.color1};
`;
