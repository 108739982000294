import React from 'react';
import PropTypes from 'prop-types';
import { Container, Header, Text } from './contactInfo.css';
// import Button from '../contactBtn/contactBtn';

const ContactInfo = ({ success }) => (
  <Container>
    <Header>{success ? 'Message received!' : 'Get in touch!'}</Header>
    <Text>
      {success
        ? 'We will be in touch soon'
        : `We'd love to hear from you, get in touch via phone, email or just fill in the
      contact form and we will get back to you as soon as possible`}
    </Text>
    <Text>
      <span>Phone:</span> +44 7929885287
    </Text>
    <Text>
      <span>Email:</span>{' '}
      <a href="mailto:ckeelty@hotmail.co.uk">ckeelty@hotmail.co.uk</a>
    </Text>
  </Container>
);

ContactInfo.propTypes = {
  success: PropTypes.bool,
};

export default ContactInfo;
