import styled from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 8rem 0 12rem;
  font-size: 0.9em;
  letter-spacing: 0.3px;
  line-height: 1.4em;
  ${MEDIA.DESKTOP`
    flex-direction: column;
    padding: 0 3rem;
    /* margin-bottom: 0; */
  `}
`;
