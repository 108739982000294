import React from 'react';
import Layout from 'components/layout';
import AboutContainer from 'components/aboutContainer';
import Head from 'components/head';
import PageContainer from 'components/pageContainer';
import ContactForm from '../components/contactForm/contactForm';
import ContactInfo from '../components/contactInfo/contactInfo';
import Footer from 'components/footer';
import SectionHeader from 'components/sectionHeader';

const Contact = () => (
  <Layout>
    <Head pageTitle="Contact" />
    <SectionHeader>Contact</SectionHeader>
    <PageContainer>
      <AboutContainer>
        <ContactInfo />
        <ContactForm />
      </AboutContainer>
    </PageContainer>
    <Footer />
  </Layout>
);

export default Contact;
