import React from 'react';
import {
  FormContainer,
  StyledForm,
  StyledInput,
  StyledTextArea,
  CustomInputLabel,
  StyledButton,
} from './contactForm.css';
// import Button from '../contactBtn/contactBtn';

const ContactForm = () => (
  <FormContainer>
    <StyledForm
      action="https://getform.io/f/3f936598-8c7f-4f1e-8fb5-eb1dec79a8f8"
      method="POST"
      name="contact"
    >
      <input type="hidden" name="form-name" value="contact" />
      <p>
        <CustomInputLabel>
          Your Name* <StyledInput type="text" name="name" required />
        </CustomInputLabel>
      </p>
      <p>
        <CustomInputLabel>
          Your Email* <StyledInput type="email" name="email" required />
        </CustomInputLabel>
      </p>
      <p>
        <CustomInputLabel>
          Message* <StyledTextArea name="message" required />
        </CustomInputLabel>
      </p>
      <p>
        <StyledButton type="submit" content="Send">
          Send
        </StyledButton>
      </p>
    </StyledForm>
  </FormContainer>
);

export default ContactForm;
