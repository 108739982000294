import styled from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';

export const Container = styled.div`
  width: 50%;
  /* padding: 2em; */
  margin-right: 2em;
  box-sizing: border-box;
  ${MEDIA.DESKTOP`
    width: 100%;
    margin-bottom: 4rem;
  `}
`;

export const Header = styled.h1`
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 1em;
  line-height: 1.3em;
`;

export const Text = styled.p`
  span {
    font-weight: bold;
  }
`;

export const StyledForm = styled.form`
  /* width: 300px; */
  display: block;
  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const StyledInput = styled.input`
  margin: 1em 0;
  display: block;
  width: 100%;
  border: none;
  padding: 1em;
  box-sizing: border-box;
`;

export const StyledTextArea = styled.textarea`
  margin: 1em 0 3em;
  display: block;
  width: 100%;
  border: none;
  height: 120px;
  resize: none;
`;

export const CustomInputLabel = styled.label``;
